<template>
  <div class="account-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
    >
      <!-- <el-table-column type="index" label="ID" align="center"></el-table-column> -->
      <el-table-column
        prop="tempId"
        label="ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="productPlanName" label="方案" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.productPlanName || "无" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        align="center"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="channelName"
        label="渠道"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="roleName" label="决策建议" align="center"></el-table-column> -->
      <el-table-column prop="requestTime" label="申请时间" align="center">
        <templae slot-scope="scope">
          <span>{{ scope.row.requestTime | toDate }}</span>
        </templae>
      </el-table-column>
      <el-table-column
        prop="virtualAmountCNY"
        label="授信额度"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stagingCount"
        label="期数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="requestDay"
        label="申请时长（天）"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="orderCount"
        label="笔数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="auditorName"
        label="初审客服"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="financeName"
        label="复审客服"
        align="center"
      ></el-table-column>
<!--      <el-table-column-->
<!--        prop="label"-->
<!--        label="备注"-->
<!--        align="center"-->
<!--        show-overflow-tooltip-->
<!--      ></el-table-column>-->
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <!-- 查看 -->
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            class="view-btn"
            circle
            @click="$emit('view', scope.row, false)"
          ></el-button>
          <el-button
            v-if="scope.row.status !== 3 && scope.row.status !== 2"
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click.native.prevent="handleEdit(scope.$index, scope.row)"
          ></el-button>
          <el-button
            v-if="scope.row.status === 3"
            size="mini"
            type="primary"
            icon="el-icon-check"
            circle
            @click.native.prevent="handleLoan(scope.$index, scope.row)"
          ></el-button>
          <!--添加备注-->
<!--          <el-button-->
<!--            type="info"-->
<!--            size="mini"-->
<!--            icon="el-icon-edit-outline"-->
<!--            circle-->
<!--            @click="handleLabel(scope.row)"-->
<!--          ></el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <!-- 操作用户 -->
    <editFrom
      :is-show="showEdit"
      :edit-data="editData"
      :product-plan="productPlan"
      @cancel="showEdit = false"
      @submit="submitEdit"
    ></editFrom>
    <!-- 放款密码 -->
    <loanFrom
      :is-show="showLoan"
      :edit-data="editData"
      @cancelL="showLoan = false"
      @submitL="submitLoan"
    ></loanFrom>
    <!-- 备注 -->
    <edit-dialog
      :isShow="showDialog"
      :memo="memo"
      @editConfirm="labelConfirm"
      @editCancel="editCancel">
    </edit-dialog>
  </div>
</template>

<script>
import service from "../api";
import editFrom from "@/views/order/secondCheck/components/editFrom";
import loanFrom from "@/views/order/secondCheck/components/loanFrom";
import dayjs from "dayjs";
import editDialog from "@/components/viewBar/reports/editDialog";

import editMemo from "@/mixins/editMemo";
export default {
  mixins:[ editMemo ]  ,
  components: { editFrom, loanFrom, editDialog },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    productPlan: {
      type: Array,
      default: () => []
    },
    //拿一下currentPage的值
    currentPage: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      showEdit: false,
      showLoan: false,
      editData: {}
    };
  },
  methods: {
    handleEdit(index, row) {
      this.showEdit = true;
      this.editData = row;
    },
    handleLoan(index, row) {
      this.showLoan = true;
      this.editData = row;
    },
    submitEdit(data) {
      let { id } = this.editData;
      let { riskPass = null, amount = null } = data;
      // return false
      return service.setFrom({ orderId: id, ...data }).then(res => {
        this.$notify({ type: "success", message: "操作成功！" });
        // this.afterSubmit({ pageNum: 1 });
        this.afterSubmit({ pageNum: this.currentPage });
      });
    },
    submitLoan(data) {
      let { id } = this.editData;
      let { loanPassword = null } = data;
      return service
        .setpass({ orderId: id, interestBeforeLoan: "0", ...data })
        .then(res => {
          // // console.log(res)
          // if (res.code == '99999') {
          //    this.$notify({ type: 'fail', message: res.msg });

          // } else {
          //   this.$notify({ type: 'success', message: '放款成功！' });
          // }
          // this.afterLoan({ pageNum: 1 })
          // this.afterLoan({ pageNum: 1 });
          this.afterLoan({ pageNum: this.currentPage });
        });
    },
    // 提交后操作
    afterSubmit(refreshParam) {
      this.showEdit = false;
      // this.$notify({ type: 'success', message: '编辑成功！' });
      this.$parent.getList(refreshParam);
    },
    afterLoan(refreshParam) {
      this.showLoan = false;
      this.$notify({ type: "success", message: "放款成功！" });
      this.$parent.getList(refreshParam);
    }
  },
  filters: {
    toDate(d) {
      return dayjs(d).format("YYYY-MM-DD");
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
</style>
