import API from "@/api";

export default {
  table: params => {
    return API.requestGet({
      url: "loan/order/financeTableList",
      params
    });
  },
  setFrom: params => {
    return API.requestPost({
      url: "loan/order/financeAudit",
      data: params,
    });
  },
  setpass: parmas =>{
    return API.requestPost({
      url: "loan/order/financeLoan",
      data: parmas,
    });
  },
  getProductPlan: params => {
    return API.requestGet({
      url: "loan/plan/list",
      params,
    });
  }
};
